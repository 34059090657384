import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import bcrypt from "bcryptjs";
import { notification, trimName } from "utils";

import * as i from "../lib";
import { getUserProfile } from "../../redux/reducers/profileReducers";

import {
  verifyOTP,
  checkOTP,
  updateEmail,
  updatePhone,
  uploadImage,
  axiosCall,
  checkPhoneOTP,
} from "../helpers";
import { getTransactionStatus } from "redux/reducers/transactionReducers";
import { setLogoutTimer } from "redux/reducers/authReducers";

const auth = i.auth;
const url = (endpoint) => `${i.baseURL}/profile/${endpoint}`;

export const useUserProfile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    try {
      unsub = i.onSnapshot(
        i.doc(i.db, "users", auth?.currentUser?.uid),
        (doc) => {
          console.log(doc.data(), "console.log of user details");
          dispatch(
            getUserProfile({
              user_details: doc.data(),
              isLoading: false,
              isLoaded: true,
            })
          );
        }
      );
    } catch (error) {
      dispatch(
        getUserProfile({
          user_details: null,
          isLoading: false,
          isLoaded: true,
        })
      );
    }

    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useUpdateProfileImage = () => {
  const [isUpdatingUpload, setUpdatingUpload] = useState(false);
  const [isUpdatingReset, setUpdatingReset] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const updateImage = async (values) => {
    const { profileImage, type } = values;
    try {
      let imageToUpload;
      if (type === "upload") {
        setUpdatingUpload(true);

        const uploadedImages = await uploadImage(
          profileImage,
          `user/${auth.currentUser.uid}`,
          auth.currentUser.uid
        );
        imageToUpload = uploadedImages[0];
      } else if (type === "reset") {
        imageToUpload =
          "https://firebasestorage.googleapis.com/v0/b/kaapo-app.appspot.com/o/avatar_esetech.png?alt=media&token=21589ce3-5c9f-466b-934c-80f6db590af6";
        if (auth.currentUser.photoURL === imageToUpload) return;
        setUpdatingReset(true);
      }

      await i.updateProfile(auth.currentUser, {
        photoURL: imageToUpload,
      });
      await i.updateDoc(i.doc(i.db, "users", auth?.currentUser?.uid), {
        avatarUrl: imageToUpload,
      });
      setUpdatingUpload(false);
      setUpdatingReset(false);
      setUpdated(true);
      notification("success", "Profile image successfully updated");
    } catch (error) {
      // console.log(error);
      setUpdatingUpload(false);
      setUpdatingReset(false);
      notification("danger", "An error occured. Try again later!", "error");
    }
  };

  return [isUpdatingUpload, isUpdatingReset, isUpdated, updateImage];
};

export const useUpdateProfile = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const updateProfile = async (values) => {
    setUpdating(true);
    const { displayName } = values;

    try {
      await i.updateProfile(auth.currentUser, {
        displayName: trimName(displayName),
      });
      await i.updateDoc(i.doc(i.db, "users", auth?.currentUser?.uid), {
        displayName: trimName(displayName),
      });
      setUpdating(false);
      setUpdated(true);
      notification("success", "Profile successfully updated.");
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      notification("danger", "An error occured. Try again later!", "error");
    }
  };

  return [isUpdating, isUpdated, updateProfile];
};

export const useUpdateCurrency = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const updateCurrency = async (values) => {
    setUpdating(true);
    const { defaultCurrency } = values;

    try {
      await i.updateDoc(i.doc(i.db, "users", auth?.currentUser?.uid), {
        defaultCurrency,
      });
      setUpdating(false);
      setUpdated(true);
      i.notification("success", "Currency successfully updated.");
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      i.notification("danger", "An error occured. Try again later.", "error");
    }
  };

  return [isUpdating, isUpdated, updateCurrency];
};

export const useVerifyPin = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const verifyPin = async (values) => {
    setUpdating(true);
    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.password
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);
      await verifyOTP(values);
      setUpdated(true);
      setUpdating(false);
      notification(
        "success",
        `OTP successfully sent to ${auth.currentUser.email}`
      );
    } catch (error) {
      setUpdating(false);
      const msg = error.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : msg === "auth/too-many-requests"
          ? "Too many failed password verify attempts. Try again later"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, verifyPin];
};

export const useUpdatePin = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const dispatch = useDispatch();

  const updatePIN = async (values) => {
    setUpdating(true);
    dispatch(getTransactionStatus(true));
    try {
      await checkOTP(values);
      const salt = bcrypt.genSaltSync(10);
      const hashed = bcrypt.hashSync(values.pin, salt);

      await i.updateDoc(i.doc(i.db, "users", auth?.currentUser?.uid), {
        transactionPIN: hashed,
      });

      setUpdating(false);
      setUpdated(true);
      dispatch(getTransactionStatus(false));
      notification("success", "PIN successfully updated.");
    } catch (error) {
      setUpdating(false);
      dispatch(getTransactionStatus(false));
      notification(
        "danger",
        [
          "Otp do not match",
          "Otp has expired",
          "Exceeded maximum trials",
        ].includes(error)
          ? error
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, updatePIN];
};

export const useUpdatePinAlt = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const dispatch = useDispatch();

  const updatePIN = async (values) => {
    setUpdating(true);
    // dispatch(getTransactionStatus(true));
    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.password
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);

      const salt = bcrypt.genSaltSync(10);
      const hashed = bcrypt.hashSync(`${values.pin}`, salt);

      await i.updateDoc(i.doc(i.db, "users", auth?.currentUser?.uid), {
        transactionPIN: hashed,
      });

      setUpdating(false);
      setUpdated(true);
      // dispatch(getTransactionStatus(false));
      notification("success", "PIN successfully updated.");
    } catch (error) {
      setUpdating(false);
      const msg = error.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : msg === "auth/too-many-requests"
          ? "Too many failed password verify attempts. Try again later"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, updatePIN];
};

export const useVerifyPass = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const dispatch = useDispatch();

  const verifyPass = async (values) => {
    setUpdating(true);
    dispatch(getTransactionStatus(true));
    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.currentPass
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);
      await verifyOTP(values);
      setUpdated(true);
      setUpdating(false);
      notification(
        "success",
        `Otp succesfully sent to ${auth.currentUser.email}.`
      );
      dispatch(getTransactionStatus(false));
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      const msg = error?.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : "An error occured. Try again later!",
        "error"
      );
      dispatch(getTransactionStatus(false));
    }
  };

  return [isUpdating, isUpdated, verifyPass];
};

export const useChangePass = () => {
  const [isUpdatingPass, setUpdating] = useState(false);
  const [isUpdatedPass, setUpdated] = useState(false);

  const dispatch = useDispatch();
  const changePass = async (values) => {
    setUpdating(true);
    try {
      dispatch(getTransactionStatus(true));
      dispatch(setLogoutTimer(false));

      await checkOTP(values);
      await i.updatePassword(auth.currentUser, values?.newPass);
      setUpdated(true);
      setUpdating(false);
      dispatch(getTransactionStatus(false));
      dispatch(setLogoutTimer(true));
      notification("success", "Password successfully updated.");
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      dispatch(getTransactionStatus(false));
      notification(
        "danger",
        ["Otp do not match", "Otp has expired"].includes(error)
          ? error
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdatingPass, isUpdatedPass, changePass];
};

export const useVerifyEmail = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const dispatch = useDispatch();

  const verifyEmail = async (values) => {
    setUpdating(true);
    try {
      dispatch(getTransactionStatus(true));
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.currentPass
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);
      const emailExist = await i.fetchSignInMethodsForEmail(
        auth,
        values.newEmail
      );
      if (!emailExist.length) {
        await verifyOTP(values);
        setUpdated(true);
        setUpdating(false);
        dispatch(getTransactionStatus(false));
        notification("success", `OTP successfully sent to ${values.newEmail}`);
      } else {
        notification("danger", "Email already exist!", "error");
        setUpdating(false);
      }
    } catch (error) {
      setUpdating(false);
      const { code } = error;
      dispatch(getTransactionStatus(false));
      notification(
        "danger",
        code === "auth/wrong-password"
          ? "Incorrect Password"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, verifyEmail];
};

export const useChangeEmail = () => {
  const [isUpdatingEmail, setUpdating] = useState(false);
  const [isUpdatedEmail, setUpdated] = useState(false);

  const dispatch = useDispatch();

  const changeEmail = async (values) => {
    dispatch(getTransactionStatus(true));
    setUpdating(true);
    try {
      // await checkOTP(values);
      await updateEmail(values);
      setUpdated(true);
      setUpdating(false);
      dispatch(getTransactionStatus(false));
      notification("success", "Email successfully updated.");
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      dispatch(getTransactionStatus(false));

      notification(
        "danger",
        ["Otp do not match", "Otp has expired"].includes(error)
          ? error
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdatingEmail, isUpdatedEmail, changeEmail];
};

export const useVerifyPhone = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [phoneCodeRef, setPhoneCodeRef] = useState("");

  const dispatch = useDispatch();

  const verifyPhone = async (values) => {
    setUpdating(true);
    try {
      dispatch(getTransactionStatus(true));
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.currentPass
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);

      const res = await verifyOTP(
        { phoneNumber: values.phone, brandName: values.brandName },
        "verify_phone_otp"
      );

      setPhoneCodeRef(res.data.data);
      setUpdated(true);
      setUpdating(false);
      dispatch(getTransactionStatus(false));
      notification("success", `Otp successfully sent to ${values.phone}`);
      return phoneCodeRef;
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      const { code } = error;
      dispatch(getTransactionStatus(false));
      notification(
        "danger",
        code === "auth/wrong-password"
          ? "Incorrect Password"
          : error === "Phone is used by another account"
          ? "Phone number already exist"
          : "An error occured. Try again later!",

        "error"
      );
    }
  };

  return [isUpdating, isUpdated, verifyPhone, phoneCodeRef];
};

export const useChangePhone = () => {
  const [isUpdatingEmail, setUpdating] = useState(false);
  const [isUpdatedEmail, setUpdated] = useState(false);

  const dispatch = useDispatch();

  const changePhone = async (values) => {
    dispatch(getTransactionStatus(true));
    setUpdating(true);
    try {
      // await checkPhoneOTP({ ...values });
      await updatePhone({ ...values, email: auth.currentUser.email });
      setUpdated(true);
      setUpdating(false);
      dispatch(getTransactionStatus(false));
      notification("success", "Phone number successfully updated.");
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      dispatch(getTransactionStatus(false));

      notification(
        "danger",
        ["Otp do not match", "Otp has expired"].includes(error)
          ? error
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdatingEmail, isUpdatedEmail, changePhone];
};

export const useUpdateWithdrawalAcc = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const {
    account: { withdrawalAccount },
  } = useStore().getState();

  const updateAccount = async (values) => {
    setUpdating(true);
    try {
      const allDetails = Object.values(withdrawalAccount || {}).reduce(
        (acc, item) => {
          acc.push(...item);
          return acc;
        },
        []
      );

      const acc = values.accounts;

      const saveBeneExist = allDetails.find(
        (item) =>
          item.accountName === acc.accountName &&
          item.bankAccountNo === acc.bankAccountNo &&
          item.bankName === acc.bankName
      );

      if (values.type === "add" && saveBeneExist) {
        setUpdating(false);
        notification("warning", "Beneficiary already saved.");
        return;
      }

      if (["add", "remove"].includes(values.type)) {
        await axiosCall(url("update_withdrawal_account"), values, true);
        setUpdated(true);
        setUpdating(false);
        notification(
          "success",
          `Account Details successfully ${
            values.type === "add" ? "added" : "removed"
          }!`
        );
      }
    } catch (error) {
      // console.log(error);
      notification("danger", "An error occured. Try again later!", "error");
      setUpdating(false);
    }
  };
  return [isUpdating, isUpdated, updateAccount];
};

export const useUpdateNotificationStatus = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const updateStatus = async (values) => {
    try {
      setUpdating(true);
      const userRef = i.doc(i.db, "users", auth.currentUser.uid);

      await i.updateDoc(userRef, {
        [`notificationStatus.${values.key}`]: values.value,
      });

      notification("success", "Notification status successfully updated");
    } catch (error) {
      // console.log(error);
      notification("danger", "An error occured. Try again later!", "error");
      setUpdating(false);
    }
  };
  return [isUpdating, isUpdated, updateStatus];
};

export const useUpdateUserCode = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const updateUserCode = async () => {
    setUpdating(true);

    const generateReferralCode = (userId) => {
      const date = new Date().getTime().toString();
      const idComb = userId.slice(0, 2) + userId.slice(-2);
      return `ET-${idComb}-${date}`;
    };
    try {
      const userCode = generateReferralCode(auth?.currentUser?.uid);
      await i.updateDoc(i.doc(i.db, "users", auth?.currentUser?.uid), {
        userCode,
      });

      await i.setDoc(i.doc(i.db, "userReferrals", auth?.currentUser?.uid), {
        userCode,
        email: auth?.currentUser?.email,
        referralList: [],
      });

      setUpdating(false);
      setUpdated(true);

      notification("success", "User Code successfully generated.");
    } catch (error) {
      const msg = error.code;
      setUpdating(false);

      notification("danger", "An error occured. Try again later!", "error");
    }
  };

  return [isUpdating, isUpdated, updateUserCode];
};
