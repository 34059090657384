import imageCompression from "browser-image-compression";
import * as i from "./lib";
import axios from "axios";

import UAParser from "ua-parser-js";
import DeviceDetector from "device-detector-js";
import { appCheck } from "./actions/utils";
import { Detector } from "detector-js";
import {  publicIpv4 } from "public-ip";

const deviceDetector = new DeviceDetector();
const parser = new UAParser();

const auth = i.getAuth();

const url = (endpoint) => `${i.baseURL}/${endpoint}`;

export const uploadImage = async (images, storagePath, fileName) => {
  const uploadedImgProm = await images.map(async (image) => {
    const compressImgOption = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

    const file = await imageCompression(image.file, compressImgOption);
    const storageRef = i.ref(
      i.getStorage(),
      `${storagePath}/${fileName || file.name}`
    );
    const snapshot = await i.uploadBytes(storageRef, file);
    const downloadURL = await i.getDownloadURL(snapshot.ref);
    return downloadURL;
  });

  return await Promise.all(uploadedImgProm);
};

const getToken = async (useLimitedToken) => {
  let { token } = await appCheck(useLimitedToken);
  return token;
};

export const callWithAuth = async (
  transData,
  url,
  appCheckToken,
  useLimitedToken
) => {
  const accessToken = await auth?.currentUser?.getIdToken();
  return {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
      "X-Firebase-AppCheck": appCheckToken,
      "X-Limited-Use": useLimitedToken,
    },
    data: {
      uid: auth?.currentUser?.uid,
      transData: { ...transData, customerId: auth?.currentUser?.uid },
    },
    url,
  };
};

const callWithNoAuth = async (transData, url, appCheckToken) => {
  return {
    method: "POST",
    url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": appCheckToken,
    },
    data: {
      transData,
    },
  };
};

export const axiosCall = (url, transData, useAuth, useLimitedToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const appCheckToken = await getToken(!!useLimitedToken);

      const option = useAuth
        ? await callWithAuth(transData, url, appCheckToken, !!useLimitedToken)
        : await callWithNoAuth(transData, url, appCheckToken);

      const res = await axios(option);
      return resolve(res);
    } catch (error) {
      let err = error;
      if (error?.response) {
        err = error?.response?.data?.error;
      }
      return reject(err);
    }
  });
};

export const axiosCall2 = async (
  requests,
  url,
  transData,
  useAuth,
  useLimitedToken
) => {
  const makeRequest = async ({ url, transData, idempotentKey }) => {
    try {
      const appCheckToken = await getToken(url, transData);

      const option = useAuth
        ? await callWithAuth(transData, url, appCheckToken, !!useLimitedToken)
        : await callWithNoAuth(transData, url, appCheckToken);

      const res = await axios(option);
      return res;
    } catch (error) {
      let err = error;
      if (error?.response) {
        err = error?.response?.data?.error;
      }
      throw err;
    }
  };

  if (Array.isArray(requests)) {
    try {
      const responses = await Promise.all(requests.map(makeRequest));
      return responses;
    } catch (error) {
      // Handle error for batch requests
      throw error;
    }
  } else {
    // Single request case
    try {
      const response = await makeRequest(requests);
      return response;
    } catch (error) {
      // Handle error for single request
      console.error("Error in single request:", error);
      throw error;
    }
  }
};

export const verifyOTP = async (
  values,
  urlLink = "verify_email_otp",
  useAuth = true
) => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    email: values?.newEmail || values?.email || auth?.currentUser?.email,
    username: values?.displayName,
    phoneNumber: values?.phoneNumber,
    brandName: values?.brandName,
    isVerify: values?.isVerify ?? true,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axiosCall(
        url(`auth/${urlLink}`),
        { values: valueFormat },
        useAuth
      );
      return resolve(res);
    } catch (error) {
      // console.log(error);
      return reject(error);

      // return reject(
      //   error.response && error.response?.data?.error
      //     ? error.response?.data?.error
      //     : error
      // );
    }
  });
};

export const verifyPhoneOTP = async (
  values,
  urlLink = "verify_phone_otp",
  useAuth = true
) => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    email: values?.newEmail || values?.email || auth?.currentUser?.email,
    username: values?.displayName,
    phoneNumber: values?.phoneNumber,
    brandName: values?.brandName,
    isVerify: values?.isVerify ?? true,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axiosCall(
        url(`auth/${urlLink}`),
        { values: valueFormat },
        useAuth
      );
      return resolve(res);
    } catch (error) {
      // console.log(error);
      return reject(error);

      // return reject(
      //   error.response && error.response?.data?.error
      //     ? error.response?.data?.error
      //     : error
      // );
    }
  });
};

export const checkOTP = async (values, urlLink = "check_email_otp") => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    email: values?.newEmail || auth?.currentUser?.email,
    code: values?.code,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axiosCall(
        url(`auth/${urlLink}`),
        { values: valueFormat },
        true
      );
      return resolve(res);
    } catch (error) {
      return reject(error);

      // return reject(
      //   error.response && error.response?.data?.error
      //     ? error.response?.data?.error
      //     : error
      // );
    }
  });
};

export const checkPhoneOTP = async (values, urlLink = "check_phone_otp") => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    code: values?.code,
    requestId: values?.phoneCodeRef,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axiosCall(
        url(`auth/${urlLink}`),
        { values: valueFormat },
        true
      );
      return resolve(res);
    } catch (error) {
      return reject(error);

      // return reject(
      //   error.response && error.response?.data?.error
      //     ? error.response?.data?.error
      //     : error
      // );
    }
  });
};

export const updateEmail = async (values) => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    email: values?.newEmail,
    code: values?.code,
  };

  const opt = callWithAuth(valueFormat, url("auth/update_email"));

  return new Promise(async (resolve, reject) => {
    try {
      const res = await i.axios(opt);
      return resolve(res);
    } catch (error) {
      return reject(
        error.response && error.response?.data?.error
          ? error.response?.data?.error
          : error
      );
    }
  });
};

export const updatePhone = async (values) => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    phoneNumber: values?.phoneNumber,
    code: values?.code,
    email: values?.email,
    requestId: values?.phoneCodeRef,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axiosCall(
        url(`auth/update_phone`),
        { values: valueFormat },
        true
      );

      return resolve(res);
    } catch (error) {
      return reject(
        error.response && error.response?.data?.error
          ? error.response?.data?.error
          : error
      );
    }
  });
};

export const updateAcct = async (values) => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    ...values,
  };

  const opt = callWithAuth(
    valueFormat,
    url("profile/update_withdrawal_account")
  );

  return new Promise(async (resolve, reject) => {
    try {
      const res = await i.axios(opt);
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDevice = async () => {
  const userAgent = parser.getResult().ua;
  const detection = new Detector();

  // console.log(detection, "device");

  const ipv4 = await publicIpv4();

  const devD = deviceDetector.parse(userAgent);
  return { ...devD, ipAddress: ipv4 };
};

export const errorHandler = (error) => {
  let message;

  const errMap = {
    "Already have an active transaction": "Already have an active transaction",
    "Unauthorized request.":
      "Action cannot be performed at the time. Try again later.",
    "timeout exceeded": "No internet connection. Check your network.",
    "Network Error": "No internet connection. Check your network.",
  };

  if (errMap.hasOwnProperty(error || error?.message)) {
    message = errMap[error];
  }

  if (message) {
    i.notification(message, "danger");
  }
};
