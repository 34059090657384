import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import bcrypt from "bcryptjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, IconBox, PopupDrawer, SectionContent } from ".";
import OTPInput from "./OTPInput";
import { useBreakpoint } from "utils";
import CreatePin from "pages/Settings/drawer-content/CreatePin";

export default function EnterPIN({ processSubmit, btnText, isSubmitting }) {
  const [OTP, setOTP] = useState("");
  const [otpError, setOtpError] = useState();
  const [openSetPin, setOpenSetPin] = useState(false);
  const [secure, setSecure] = useState(true);
  const { user_details } = useSelector((state) => state.profile);

  useEffect(() => {
    console.log(user_details, "user_details");
    if (OTP?.length === 6) {
      const pin = user_details?.transactionPIN;
      const checkPin = bcrypt.compareSync(OTP, pin);
      setOtpError(checkPin);
    } else {
      setOtpError();
    }
  }, [OTP]);

  return (
    <>
      {user_details?.transactionPIN ? (
        <>
          <div className="p-4 bg-white border rounded-lg border-slate-200">
            <div className="text-sm text-center">
              <p>Enter your PIN to complete transaction</p>
              <div className="flex flex-col items-center">
                <button onClick={() => setSecure(!secure)}>
                  {!secure ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </button>
                <div>
                  <OTPInput
                    value={OTP}
                    // onChange={handleComparePIN}
                    onChange={setOTP}
                    OTPLength={6}
                    otpType={"number"}
                    disabled={isSubmitting}
                    secure={secure}
                    inputClassName="border rounded border-gray-500 text-gray-500 focus:border-primary outline-none focus:border-2 focus:rounded-md text-[16px]"
                  />

                  {otpError !== undefined ? (
                    <>
                      {!otpError && (
                        <span className="text-xs text-red-500 error-message">
                          PIN does not match
                        </span>
                      )}
                    </>
                  ) : null}
                </div>
                {/* <div className="w-full mt-6 action">
              <div className="flex justify-between space-x-2 action-buttons">
              <Button label="Reset PIN" link="/settings" color="default" />
              <Button
                label="Close"
                handleClick={() => setOpen(false)}
                variant="outlined"
                color="neutral"
              />
            </div>
          </div> */}
              </div>
            </div>
          </div>
          {otpError && (
            <div className="mt-2">
              <Button
                fullWidth
                label={btnText}
                gradient
                handleClick={processSubmit}
                isSubmitting={isSubmitting}
              />
            </div>
          )}
        </>
      ) : (
        <div className="h-full col-span-1 p-2 bg-gray-50 md:col-span-3 lg:col-span-2">
          <div className="p-6 bg-white rounded-lg shadow-card-box">
            <div className="flex flex-col items-center justify-center">
              <IconBox icon={"PriorityHigh"} gradient shadowAlt />
              <p className="mt-4 text-sm">Create PIN to continue.</p>
              <div className="mt-2 action">
                <div className="action-buttons">
                  <Button
                    label="Create PIN"
                    gradient
                    // link="/settings"
                    // state={{ page: 1 }}
                    handleClick={() => setOpenSetPin(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <PopupDrawer open={openSetPin} setOpenDrawer={setOpenSetPin}>
        <div className="p-4 bg-gray-50 sm:flex sm:px-4">
          <h5 className="font-bold">{"Create PIN"}</h5>
        </div>
        <div className="mt-0 bg-[#f4f8f5] p-2">
          <div className="drawer-content">
            <SectionContent>
              <CreatePin setDrawer={setOpenSetPin} />
            </SectionContent>
          </div>
        </div>
      </PopupDrawer>
    </>
  );
}
